import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import Hero from '../components/hero';

function Confirmation({}) {
  return (
    <Layout>
      <Hero title="Thank you" color="light" />
      <section className="section content">
        <div className="container">We have received your submission!</div>
      </section>
    </Layout>
  );
}

Confirmation.propTypes = {};

export default Confirmation;
